<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page></loading>
        <div v-if="!isPageLoading">
          <div class="page-title">
            <h1>News</h1>
          </div>
          <div class="page-content">
            <div class="d-flex align-items-center mb-5 bd-highlight" >
              <div class="ml-auto">
                <button type="button" class="btn btn-primary" @click="newNewsItem">
                  <i class="fas fa-plus"></i>
                  Add News Item
                </button>
              </div>
            </div>

            <div class="alert alert-warning mt-3" role="alert" v-if="!getNewsList.length">
              No News available.
            </div>

            <div class="table-responsive mt-3" v-else>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <td scope="col"> News Title </td>
                    <td scope="col"> News Summary </td>
                    <td scope="col"> News Link </td>
                    <td scope="col"> Share With </td>
                    <td scope="col"> Created Date </td>
                    <td scope="col" width="75px"> &nbsp; </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(entry, eIndex) in getNewsList" :key="'news-' + eIndex">
                    <td> {{ entry.title }} </td>
                    <td> {{ entry.summary }} </td>
                    <td> {{ entry.link }} </td>
                    <td>
                      <span v-for="(zone, zIndex) in entry.zoneAbbreviations" :key="'news-zone-' + zIndex">
                        {{ zone }}
                      </span>
                    </td>
                    <td> {{ entry.createdOn || 'N/A' | datetime('MMMM dd yyyy, h:mm aa') }} </td>
                    <td>
                      <div class="d-flex align-items-start">
                        <div class="pl-2 pr-2 flex-fill">
                          <a href="#" role="button" title="Edit" class="d-flex justify-content-between align-items-center flex-column"
                              @click.prevent="editNewsItem(entry.id)">
                            <img src="@/assets/document.svg" alt="" class="mb-1">
                            <span class="small-grey">Edit</span>
                          </a>
                        </div>
                        <div class="pl-2 pr-2 flex-fill">
                          <a href="#" role="button" title="Delete" class="d-flex justify-content-between align-items-center flex-column"
                              @click.prevent="deleteNewsItem(entry.id)">
                            <img src="@/assets/red_x.svg" alt="" class="mb-1">
                            <span class="small-grey">Delete</span>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-news-article" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <news-article></news-article>
    </b-modal>
  </fragment>
</template>

<style scoped>
  h2 {
    font-size: 18px;
  }
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import MessageBox from "@/utils/messagebox";
import Toast from "@/utils/toast";

export default {
  name: "news",
  components: {
    'app-navbar': () => import('@/components/app-navbar.vue'),
    'news-article': () => import('@/views/configuration/components/news-article.vue')
  },
  data: function() {
    return {
      isPageLoading: true,
      maxAllowedNewsItems: 5
    };
  },
  computed: {
    ...mapGetters(["getNewsList", "getIsNewsEnabled", "getZones"])
  },
  methods: {
    toggleNews (e) {
      let selection = e.target.checked;
      this.toggleNewsEnabled(selection).then(() => {
        Toast.success(this, `News are now ${ selection ? 'enabled' : 'disabled' }`);
      });
    },
    newNewsItem() {
      this.resetNews().then(() => {
        this.$bvModal.show("bv-modal-news-article");
      });
    },
    editNewsItem(id) {
      this.fetchNews(id).then(() => {
        this.$bvModal.show("bv-modal-news-article");
      });
    },
    async deleteNewsItem(id) {
      let confirm = await MessageBox.confirm(this, "Remove News Item", "Are you sure you want to remove news item?");

      if (confirm) {
        this.removeNews(id).then(() => {
          Toast.success(this, "Successfully removed news item!");
        });
      }
    },
    ...mapActions(["fetchNews", "fetchNewsList", "removeNews", "resetNews", "toggleNewsEnabled", "fetchZones"])
  },
  mounted: function() {
    Promise.all([
      this.fetchNewsList(),
    ])
    .then(() => {
      this.isPageLoading = false;
    });
  }
};
</script>
